module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Double","short_name":"Double","start_url":"/","background_color":"#000000","theme_color":"#6650f2","icon":"src/assets/images/double-logo-sq.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f01fc5b333b17fb3a6446823eda84a3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EVJRT9T5XM","AW-10975360890"],"pluginConfig":{"head":true}},
    }]
